import React, { useCallback, useMemo } from "react";
import SwiperCarousel from "../../components/SwiperCarousel/SwiperCarousel";
import styles from "./TeachFeaturedCarousel.module.scss";

const slow = 3000;
const fast = 1000;

interface TeachFeaturedCarouselProps {
  carouselItems: JSX.Element[];
  itemTypeMessage: string;
  hero?: boolean;
}

const TeachFeaturedCarousel = ({
  carouselItems,
  itemTypeMessage,
  hero = false
}: TeachFeaturedCarouselProps) => {
  // Pass this in as a prop for other carousels?
  const breakpoints = useMemo(() => {
    if (typeof window === "undefined") {
      return;
    }
    return {
      // When window width is >= 0px.
      0: {
        slidesPerView: 2.4,
        spaceBetween: 15,
        slidesPerGroup: 2,
        slidesOffsetAfter: (window.outerWidth / 2.4) * 0.2,
        slidesOffsetBefore: (window.outerWidth / 2.4) * 0.2
      },
      // When window width is >= 768px.
      768: {
        slidesPerView: 3.4,
        spaceBetween: 20,
        slidesPerGroup: 3,
        slidesOffsetAfter: (window.outerWidth / 3.4) * 0.2,
        slidesOffsetBefore: (window.outerWidth / 3.4) * 0.2
      },
      // When window width is >= 992ppx.
      992: {
        slidesPerView: 4.4,
        spaceBetween: 25,
        slidesPerGroup: 4,
        slidesOffsetAfter: (window.outerWidth / 4.4) * 0.2,
        slidesOffsetBefore: (window.outerWidth / 4.4) * 0.2
      },
      // When window width is >= 1200px.
      1200: {
        slidesPerView: 5.4,
        spaceBetween: 30,
        slidesPerGroup: 5,
        slidesOffsetAfter: (window.outerWidth / 5.4) * 0.2,
        slidesOffsetBefore: (window.outerWidth / 5.4) * 0.2
      },
      // When window width is >= 1400px.
      1400: {
        slidesPerView: 6.4,
        spaceBetween: 30,
        slidesPerGroup: 6,
        slidesOffsetAfter: (window.outerWidth / 6.4) * 0.2,
        slidesOffsetBefore: (window.outerWidth / 6.4) * 0.2
      }
    };
  }, []);

  const getSlidesPerView = useCallback(() => {
    if (typeof window !== "undefined" && breakpoints) {
      if (window.outerWidth < 768) {
        return breakpoints[0].slidesPerView;
      } else if (window.outerWidth < 992) {
        return breakpoints[768].slidesPerView;
      } else if (window.outerWidth < 1200) {
        return breakpoints[992].slidesPerView;
      } else if (window.outerWidth < 1400) {
        return breakpoints[1200].slidesPerView;
      } else {
        return breakpoints[1400].slidesPerView;
      }
    }
  }, [breakpoints]);

  return (
    <SwiperCarousel
      autoHeight={hero}
      autoplay={
        hero
          ? {
              disableOnInteraction: true,
              delay: 10_000,
              pauseOnMouseEnter: true
            }
          : false
      }
      breakpoints={
        hero
          ? {
              0: {
                slidesPerView: 1,
                spaceBetween: 0
              }
            }
          : breakpoints
      }
      carouselItems={carouselItems}
      itemTypeMessage={itemTypeMessage}
      loop={hero}
      navigation={!hero}
      onResize={swiper => {
        if (hero || typeof window === "undefined") {
          return;
        }
        const slidesPerView = getSlidesPerView();
        if (slidesPerView) {
          swiper.params.slidesOffsetAfter =
            (window.outerWidth / slidesPerView) * 0.2;
          swiper.params.slidesOffsetBefore =
            (window.outerWidth / slidesPerView) * 0.2;
        }
      }}
      // Padding to account for card box-shadow.
      padding={hero ? 0 : "1rem 12px 12px 12px"}
      speed={hero ? slow : fast}
      className={styles.root}
    />
  );
};
export default TeachFeaturedCarousel;
